import { Component, OnInit } from "@angular/core";
import { ConstantsService } from "../../services/constants.service";

@Component({
  selector: "booking-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  contactPersons: { name: string; email: string }[] = [];

  constructor(public constants: ConstantsService) {}

  ngOnInit(): void {
    this.contactPersons.push({
      name: "Răzvan Bărăgan",
      email: "constantin.baragan@ligaac.ro",
    });
    this.contactPersons.push({
      name: "Eduard Cziszter",
      email: "eduard.cziszter@ligaac.ro",
    });
    this.contactPersons.push({
      name: "Adelin Chiș",
      email: "adelin.chis@ligaac.ro",
    });
    this.contactPersons.push({
      name: "Dan Percic",
      email: "dan.percic@ligaac.ro",
    });
    this.contactPersons.push({
      name: "Gheorghe Dârle",
      email: "gheorghe.darle@ligaac.ro",
    });
    this.contactPersons.push({
      name: "Denis Troncotă",
      email: "denis.troncota@ligaac.ro",
    });
    this.contactPersons.push({
      name: "Vladko Lasak",
      email: "vladko.lasak@ligaac.ro",
    });
    this.contactPersons.push({
      name: "Cristian Vîjdea",
      email: "cristian.vîjdea@ligaac.ro",
    });
    this.contactPersons.push({
      name: "+Echipa Student Office UPT (student.office@upt.ro, +40 725 890 971)",
      email: "student.office@upt.ro",
    });
  }
}
